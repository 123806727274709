import React, { useState } from "react";
import { format } from "date-fns";

import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import { Datepicker } from "baseui/datepicker";
import { TimePicker } from "baseui/timepicker";
import { SIZE } from "baseui/input";
import { Checkbox } from "baseui/checkbox";

export default function ReservationValidationForm(props) {
  const slots = props.slots;

  const [valueChoice, setValueChoice] = useState("client");
  const [valueSuggest, setValueSuggest] = useState(null);
  const [valueDate, setValueDate] = useState(null);

  function setChoiceValue(value) {
    setValueChoice(value);
    updateFormData(null, null);
    if (value === "client") {
      setValueDate(null);
      updateFormData(valueSuggest, null);
    } else if (value === "suggest") {
      setValueSuggest(null);
      updateFormData(null, valueDate);
    }
  }

  function setSuggestValue(value) {
    // console.log('setSuggestValue',value);
    setValueSuggest(value);
    updateFormData(value, null);
  }

  function updateFormData(slot, date) {
    props.updateFormData(slot, date);
  }

  function setDate(value) {
    // console.log("setDate", value);
    setValueDate(value);
    updateFormData(null, value);
  }

  return (
    <React.Fragment>
      <Checkbox
        checked={valueChoice === "client"}
        onChange={() => setChoiceValue("client")}
      >
        Propositions du client
      </Checkbox>

      <div
        style={{
          marginLeft: "30px",
        }}
      >
        <RadioGroup
          id="radio-slots"
          value={valueSuggest}
          onChange={(e) => {
            setSuggestValue(e.target.value);
          }}
          name="slots"
          align={ALIGN.vertical}
          disabled={valueChoice !== "client"}
        >
          {slots.map((slot, index) => {
            return (
              <Radio key={index} value={(index + 1).toString()}>
                {format(new Date(slot.date), "dd/MM/yyyy") + " à " + slot.heure}
              </Radio>
            );
          })}
        </RadioGroup>
      </div>
      <div className="or-divider"></div>
      <Checkbox
        checked={valueChoice === "suggest"}
        onChange={() => setChoiceValue("suggest")}
      >
        Proposer une date
      </Checkbox>

      <div className="flex-row">
        <div
          style={{
            marginRight: "20px",
          }}
        >
          <Datepicker
            className="cy-input"
            onChange={({ date }) => setDate(date)}
            formatString="dd/MM/yyyy"
            size={SIZE.compact}
            value={valueDate}
            minDate={new Date()}
            disabled={valueChoice === "client"}
          />
        </div>
        <div className="cy-input">
          <TimePicker
            onChange={(date) => setDate(date)}
            nullable
            value={valueDate}
            size={SIZE.compact}
            disabled={!valueDate || valueChoice === "client"}
            format="24"
          />
        </div>
      </div>
    </React.Fragment>
  );
}
