export const LIMIT = 10;

export const handlePageChange = (nextPage, data, that) => {
  //TODO: refactor maybe
  if (nextPage < 1) {
    return;
  }
  if (nextPage > Math.ceil(data.length / that.state.limit)) {
    return;
  }
  that.setState({ page: nextPage });

};

export const handleLimitChange = (nextLimit, data, that) => {
  const nextPageNum = Math.ceil(data.length / nextLimit);
  if (nextPageNum < that.state.page) {
    that.setState({ limit: nextLimit, page: nextPageNum });

  } else {
    that.setState({ limit: nextLimit });

  }
};

export const window = (data, that) => {
  const min = (that.state.page - 1) * that.state.limit;
  return data.slice(min, min + that.state.limit);
};