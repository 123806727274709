import React, { Component } from 'react';
import CompanyLogo from './CompanyLogo';
import FormContent from './FormContent';

class LoginForm extends Component {
    render() {
        return (
            <div className="login-form-container" >
                <div className="login-form-content">
                    <CompanyLogo />
                    <FormContent />
                </div>
            </div>
        );
    }
}

export default LoginForm;