/* Action Types */
export const actionTypes = {
  SET_NEW_RESERVATION: "SET_NEW_RESERVATION",
  SET_RESERVATIONS: "SET_RESERVATIONS",
  SET_RESERVATIONS_STATUS: "SET_RESERVATIONS_STATUS",
};

/* Actions */
export function setNewReservations(payload) {
  return { type: actionTypes.SET_NEW_RESERVATION, payload };
}

export function setReservations(payload) {
  return { type: actionTypes.SET_RESERVATIONS, payload };
}

export function setReservationsStatus(payload) {
  return { type: actionTypes.SET_RESERVATIONS_STATUS, payload };
}
