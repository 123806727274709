import { combineReducers } from "redux";
import reservations from "./reservations";
import user from "./user";

const rootReducer = combineReducers({
  reservations,
  user,
});

export default rootReducer;
