import React, { Component } from 'react';

export default class NoConnexion extends Component {
    render() {
        return (
            <div>
                <div className="flex-row">
                    <div className="img-container">
                        <div className="no-connexion-img"></div>
                    </div>
                </div>
                <div>
                    <h1 className="custom-title"> Impossible de se connecter au serveur! Veuillez réessayer ultérieurement. </h1>
                </div>
            </div>
        )
    }
}