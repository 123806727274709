import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import {SIZE, KIND} from 'baseui/button'

export default function ConfirmationDialog (props) {

  const [isOpen, setIsOpen] = React.useState(true);

  function close(action) {
    setIsOpen(false);
    props.closeConfirmationDialog(action)
  }

  function cancel(){
    close(false)
  }

  function confirm(){
    close(true)
  }

  return (
    <React.Fragment>
      <Modal onClose={cancel} isOpen={isOpen}>
        <ModalHeader> {props.title}</ModalHeader>
        <ModalBody>
            {props.message}
        </ModalBody>
        <ModalFooter>
          <ModalButton onClick={cancel} size={SIZE.compact} kind={KIND.secondary}>Annuler</ModalButton>
          <ModalButton onClick={confirm} size={SIZE.compact} autoFocus>Confirmer</ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};