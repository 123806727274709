/* eslint-disable eqeqeq */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/* Components */
import LoadingComp from "../../shared/LoadingComponent";
import NoConnexion from "../../shared/NoConnexionComponent";
import Empty from "../../shared/EmptyList";
import ReservationsTable from "./ReservationsTable";

import { setReservations } from "../../../redux/actions/reservationActions";
import { getReservations } from "../../../utils/reservationsUtils";

import { Button, SIZE } from "baseui/button";
import ExportModal from "./ExportModal";

import { RESERVATION } from "../../../utils/router";

class ReservationsList extends Component {
  constructor(props) {
    super(props);

    this.getReservations = this.getReservations.bind(this);
    this.handleReservation = this.handleReservation.bind(this);
    this.handleExport = this.handleExport.bind(this);

    this.state = {
      errCnx: false,
      isLoading: true,
      empty: false,

      status: props.status,
      date: props.date,
      keyword: props.keyword,

      showExportModal: false,
    };
  }

  componentDidMount() {
    this.getReservations(this.state.date, this.state.keyword);
  }

  componentWillReceiveProps(nextProps) {
    let date = nextProps.date;
    let keyword = nextProps.keyword;
    if (date) this.setState({ date: date });
    if (keyword) this.setState({ keyword: keyword });
    this.getReservations(date, keyword);
  }

  getReservations(date = null, keyword = null) {
    this.setState({ isLoading: true, empty: false, errCnx: false });

    // console.log("State & date",date)
    const that = this;
    getReservations(this.state.status, date, keyword, null, null, function(
      reservations,
      code
    ) {
      if (code == 200) {
        if (reservations.totalDocs == 0) {
          that.setState({ empty: true });
        }
        // console.log(status, reservations)
        that.props.setReservations(reservations);
        // console.log('total count',reservations.totalDocs)
        that.props.updateCount(reservations.totalDocs);
      } else {
        if (code == 404) that.setState({ empty: true });
        else {
          that.setState({ errCnx: true });
        }
      }
      that.setState({ isLoading: false });
    });
  }

  handleReservation(reservation) {
    // console.log('in parent handleReservation', reservation.reservation_id)
    let path = RESERVATION + `/` + reservation.reservation_id;
    this.props.history.push(path);
  }

  handleExport() {
    this.setState({
      showExportModal: !this.state.showExportModal,
    });
  }

  render() {
    // console.log('render',this.state)
    return (
      <div>
        {this.state.showExportModal ? (
          <ExportModal
            show={this.state.showExportModal}
            close={this.handleExport}
          />
        ) : null}
        <div className="reservations-list settings">
          {this.props.export ? (
            <div className="reservations-export">
              <Button
                size={SIZE.compact}
                onClick={this.handleExport}
                startEnhancer={() => <i className="icon ion-md-download"></i>}
              >
                Exporter
              </Button>
            </div>
          ) : null}
          <div className="flex-row">
            <div className="flex-col list-wrapper">
              <div className="list-wrapper__container">
                <div className="flex-col">
                  {this.state.isLoading ? (
                    <LoadingComp />
                  ) : this.state.empty ? (
                    <Empty message="Aucun réservation dans cette catégorie ! " />
                  ) : this.state.errCnx ? (
                    <NoConnexion />
                  ) : (
                    <ReservationsTable
                      status={this.state.status}
                      handleReservation={this.handleReservation}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reservations: state.reservations.reservations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setReservations: (data) => {
      dispatch(setReservations(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReservationsList));
