import React, { Component } from "react";
import CustomInput from "../CustomInput";
import SubmitBtn from "./SubmitBtn";
import { login } from "../../../utils/auth";
import { withRouter } from "react-router-dom";

import { Formik } from "formik";

import { Notification, KIND } from "baseui/notification";

//redux
import { connect } from "react-redux";
import { setUserInfo } from "../../../redux/actions/userActions";

import {routes} from "../../../utils/router"

class FormContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isError: false,
      errorMsg: "",
    };
  }

  formValidation = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "L'email est obligatoire";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "L'email est invalide";
    }
    if (!values.password) {
      errors.password = "Le mot de passe est obligatoire";
    }
    this.setState({ isError: false, errorMsg: "" });
    return errors;
  };

  onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    login(values.email, values.password, (status_code, msg, user) => {
      switch (status_code) {
        case 200:
          this.props.setUserInfo({
            isLoggedIn: true,
            ...user,
          });
          this.props.history.push(routes.DASHBOARD);
          break;
        default:
          setSubmitting(false);
          this.setState({ isError: true, errorMsg: msg });
          break;
      }
    });
  };

  render() {
    return (
      <div className="form-content">
        {this.state.isError ? (
          <Notification
            overrides={{
              Body: { style: { width: "auto" } },
            }}
            kind={KIND.negative}
          >
            {this.state.errorMsg}
          </Notification>
        ) : (
          ""
        )}
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={this.formValidation}
          onSubmit={this.onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <CustomInput
                type="email"
                name="email"
                label="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && errors.email ? errors.email : ""}
              />
              <CustomInput
                label="mot de passe"
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.password && errors.password ? errors.password : ""
                }
              />
              <SubmitBtn disabled={isSubmitting} />
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserInfo: (data) => {
      dispatch(setUserInfo(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FormContent));
