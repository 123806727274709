export const RES_STATE_NEW = 1;
export const RES_STATE_VALID = 2;
export const RES_STATE_CURRENT = 3;
export const RES_STATE_CANCELED = 4;
export const RES_STATE_FINISHED = 5;
export const RES_STATE_CLIENT_ABSENT = 6;
export const RES_STATE_MISS_MATCH = 7;
export const RES_STATE_ALL = 0;
export const RES_STATE_HISTORY = -1;

export const RES_STATUS = {
	1: "En Attente",
	2: "À Venir",
	3: "En Cours",
	4: "Annulée",
	5: "Terminée",
	6: "Non Honoré",
	7: "Missmatch"
}

export function statusToTagKind(status) {
	switch (status) {
		case RES_STATE_VALID: {
			return 'positive';
		}
		case RES_STATE_MISS_MATCH: {
			return 'positive';
		}
		case RES_STATE_NEW: {
			return 'accent';
		}
		case RES_STATE_CANCELED: {
			return 'negative';
		}
		case RES_STATE_CLIENT_ABSENT: {
			return 'negative';
		}
		default: {
			return 'warning';
		}
	}
}