import React from "react";
import { axiosApiInstance, refreshAccessToken } from "../utils/apiUtils";
import { logout } from "../utils/auth";

export function Interceptor(props) {
  React.useEffect(() => {
    axiosApiInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async function(error) {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const access_token = await refreshAccessToken();
          if (access_token) {
            originalRequest.headers.Authorization = "Bearer " + access_token;
            return axiosApiInstance(originalRequest);
          }
        }

        if (error.response.status === 401 && originalRequest._retry) {
          //localstorage
          logout();
          //redux state
          props.logoutUser();
        }

        return Promise.reject(error);
      }
    );
  }, []);

  return null;
}
