import * as React from "react";
import { Button, SHAPE, SIZE } from "baseui/button";
import Show from "baseui/icon/show";
import { Modal, ModalHeader, ModalBody } from "baseui/modal";
import { useStyletron } from "baseui";
import { URL_FILES } from "../../../utils/apiUtils";
import { downloadfile } from "../../../utils/reservationsUtils";

import { ImFolderDownload } from "react-icons/im";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../../style/carousel.css";

import { toast } from "react-toastify";

// Import pdf viewer main components
import { Viewer } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
// Import the styles
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";

//docx file viewer
import FileViewer from "react-file-viewer";

const ImageViewer = ({ src }) => {
  const [css] = useStyletron();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "60vh",
        width: "100%",
      }}
    >
      <div style={{ width: "92%", height: "100%" }}>
        <img
          crossOrigin="anonymous"
          src={src}
          alt="Ordonnace"
          className={css({
            width: "100%",
            height: "100%",
            objectFit: "contain",
          })}
        />
      </div>
    </div>
  );
};

const PdfViewer = ({ src, width }) => {
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  return (
    <div
      style={{
        width: "100%",
        height: "60vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: width,
          height: "100%",
          width: "92%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#eeeeee",
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            justifyContent: "center",
            padding: "4px",
          }}
        >
          <ZoomOutButton />
          <ZoomPopover />
          <ZoomInButton />
        </div>
        <Viewer fileUrl={src} plugins={[zoomPluginInstance]} />
      </div>
    </div>
  );
};

const DocxViewer = ({ src, width }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "60vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ height: "100%", width: "92%" }}>
        <FileViewer fileType={"docx"} filePath={src} />
      </div>
    </div>
  );
};

export default (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [filePath, setFilePath] = React.useState(
    props.path.split("*").length === 1 ? props.path : props.path.split("*")[0]
  );

  return (
    <React.Fragment>
      <div className="file-visualizer">
        <Button shape={SHAPE.round} onClick={() => setIsOpen(true)}>
          <Show size={15} />
        </Button>
        <Modal
          onClose={() => setIsOpen(false)}
          isOpen={isOpen}
          overrides={{
            Dialog: {
              style: {
                width: "50vw",
                height: "80vh",
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
              },
            },
          }}
        >
          <ModalHeader>
            <div style={{ display: "flex" }}>
              <div style={{ width: "20%" }}>{props.title}</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "80%",
                  paddingRight: "30px",
                }}
              >
                <div>
                  {props.path.split("*").length > 1 && (
                    <Button
                      startEnhancer={() => <ImFolderDownload />}
                      size={SIZE.compact}
                      onClick={() => {
                        props.path.split("*").forEach((name) => {
                          downloadfile(URL_FILES + name, (status_code) =>
                            status_code === 200
                              ? toast.success(
                                  "Le fichier a été téléchargé avec succès"
                                )
                              : toast.error(
                                  "Une erreur est survenue! veuillez réessayer"
                                )
                          );
                        });
                      }}
                      style={{ marginRight: "10px" }}
                    >
                      Tout télécharger
                    </Button>
                  )}
                </div>
                <div>
                  <Button
                    startEnhancer={() => (
                      <i className="icon ion-md-download"></i>
                    )}
                    size={SIZE.compact}
                    onClick={() => {
                      downloadfile(URL_FILES + filePath, (status_code) =>
                        status_code === 200
                          ? toast.success(
                              "Le fichier a été téléchargé avec succès"
                            )
                          : toast.error(
                              "Une erreur est survenue! veuillez réessayer"
                            )
                      );
                    }}
                  >
                    Télécharger ce fichier
                  </Button>
                </div>
              </div>
            </div>
          </ModalHeader>
          <ModalBody style={{ flex: "1 1 0", padding: "0", margin: "0" }}>
            {props.path.split("*").length === 1 ? (
              props.path.match(/[a-zA-Z0-9]+.(png|jpg|jpeg)/i) ? (
                <ImageViewer src={URL_FILES + props.path} />
              ) : props.path.match(/[a-zA-Z0-9]+.pdf/) ? (
                <PdfViewer src={URL_FILES + props.path} />
              ) : (
                props.path.match(/[a-zA-Z0-9]+.(docx|doc)/) && (
                  <DocxViewer src={URL_FILES + props.path} />
                )
              )
            ) : (
              <Carousel
                showStatus={false}
                onChange={(index) => setFilePath(props.path.split("*")[index])}
              >
                {props.path.split("*").map((file, index) => {
                  return (
                    <div key={index}>
                      {file.match(/[a-zA-Z0-9]+.(png|jpg|jpeg)/i) ? (
                        <ImageViewer src={URL_FILES + file} />
                      ) : file.match(/[a-zA-Z0-9]+.pdf/) ? (
                        <PdfViewer src={URL_FILES + file} width="95%" />
                      ) : (
                        file.match(/[a-zA-Z0-9]+.(docx|doc)/) && (
                          <DocxViewer src={URL_FILES + file} width="95%" />
                        )
                      )}
                    </div>
                  );
                })}
              </Carousel>
            )}
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};
