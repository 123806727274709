import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE
} from "baseui/modal";

import {Notification} from 'baseui/notification';

import ExportDateSelect from './ExportDateSelect';

import { exportReservations } from '../../../utils/reservationsUtils';


export default ({show, close}) => {

  const [dates, setDates] = useState([new Date()]);
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState("");

  function handleExport() {
  let startDate = dates[0];
  let endDate = dates[1];

  try {
    setLoading(true)
     exportReservations(startDate, endDate, function(code) {
      switch(code){
        case 200:
          setResponse("Vos réservations ont bien été exportées")
          setLoading(false)
          break;
        case 400:
          setResponse("Veuillez donner un interval valide")
          setLoading(false)
          break;
        case 404:
          setResponse("Aucune réservation n'a été trouvée")
          setLoading(false)
          break;
        default:
          setResponse("Erreur serveur")
          setLoading(false)
          break;
      }
    })
    } catch(err) {
      console.error(err)
    }
  }

  return (  

    <Modal
      onClose={close}
      closeable
      isOpen={show}
      animate
      autoFocus
      size={SIZE.auto}
      role={ROLE.alertdialog}
      unstable_ModalBackdropScroll={true}
    >
      <ModalHeader>Veuillez choisir l'interval de dates de réservations a exporter</ModalHeader>
      <ModalBody>
        {response !== "" ?  <Notification>{response}</Notification> : null}
        <ExportDateSelect setDates={setDates} dates={dates}/>
      </ModalBody>
      <ModalFooter>
        {dates[0]===undefined || dates[1]===undefined ? <ModalButton disabled>Exporter</ModalButton> : isLoading ? <ModalButton onClick={handleExport} isLoading>Exporter</ModalButton> : <ModalButton onClick={handleExport}>Exporter</ModalButton>}
      </ModalFooter>
    </Modal>
  );
}