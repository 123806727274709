import React, {Component} from 'react';

export default class EmptyList extends Component{
    render(){
        return(
            <div className="empty-list">
                <div className="empty-list__container">
                    <div className="empty-list__container__img">
                        
                    </div>
                    <h4>{this.props.message}</h4>
                </div>
            </div>
        )
    }
}