import React from "react";
import { Route, Redirect } from "react-router-dom";
import { routes } from "../utils/router";

const PrivateRoute = ({
  component: Component,
  isLoggedIn,
  isAdmin,
  ...rest
}) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component isAdmin={isAdmin} isLoggedIn={isLoggedIn} {...props} />
        ) : (
          <Redirect to={routes.LOGIN} />
        )
      }
    />
  );
};

export default PrivateRoute;
