import { getUserInfo } from "../../utils/auth";
import { userActions } from "../actions/userActions";

const initState = getUserInfo();

const reSetState = {
  isLoggedIn: false,
  nom: "",
  prenom: "",
  role: 0,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case userActions.SET_INFO:
      return { ...state, ...action.payload };
    case userActions.LOGOUT:
      return { ...reSetState };
    default:
      return state;
  }
};

export default reducer;
