import { BaseProvider, createTheme, lightThemePrimitives } from "baseui";
import React from "react";
import ReactDOM from "react-dom";
//redux
import { Provider } from "react-redux";
import { Client as Styletron } from "styletron-engine-atomic";
//Base UI
import { Provider as StyletronProvider } from "styletron-react";
import App from "./App";
import store from "./redux/store";
import "./style/index.css";


const engine = new Styletron();

const primitives = {
  ...lightThemePrimitives,
  primary: "#436797",
  primaryDarker: "#30496b",
  secondary: "#964267",
};
const overrides = {
  colors: {
    buttonPrimaryFill: primitives.primary,
    buttonPrimaryActive: primitives.accent300,
    buttonPrimaryHover: primitives.primaryDarker,

    accentColor: primitives.secondary,
  },
};
const theme = createTheme(lightThemePrimitives, overrides);

ReactDOM.render(
  <Provider store={store}>
    <StyletronProvider value={engine}>
      <BaseProvider theme={theme}>
        
          <App />
        
      </BaseProvider>
    </StyletronProvider>
  </Provider>,
  document.getElementById("root")
);
