
import * as React from 'react';

import FileVisualizer from './FileVisualizer'

export default function ReservationInfosBox(props) {
    // console.log('ReservationInfosBox', props.reservation)
    const reservation = props.reservation;

    return (
        <React.Fragment>
            <div>

                <div className="info-card">
                    <div className="info-item">
                        <p className="info-item-label">Nom et prénom</p>
                        <p className="info-text">{reservation.nom + " " + reservation.prenom}</p>
                    </div>
                    <div className="info-item">
                        <p className="info-item-label">Téléphone</p>
                        <p className="info-text">{reservation.phone}</p>
                    </div>
                    { reservation.email? 
                    <div className="info-item">
                        <p className="info-item-label">Email</p>
                        <p className="info-text">{reservation.email}</p>
                    </div>
                    : ""
                    }
                    <div className="info-item">
                        <p className="info-item-label">Adresse</p>
                        <p className="info-text">
                            {
                                reservation.commune?
                                reservation.commune.nom + ", "+ reservation.commune.wilaya_id.nom
                                : " - "
                            }
                        </p>
                    </div>
                    {/* <div className="info-item">
                        <p className="info-item-label">TYPE D'EXAMEN</p>
                        <p className="info-text">IRM Cervicale</p>
                    </div> */}
                    <div className="info-item">
                        <p className="info-item-label">Fichier joint</p>
                        <FileVisualizer path={reservation.ordonnance_path} title="Ordonnace"/>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
};