import React from 'react';

import { Select, SIZE } from "baseui/select";

export default function ReservationDateSelect(props) {
    const [value, setValue] = React.useState([]);

    return (
        <div className="cy-input">
            <Select
                options={[
                    { label: "Aujourd'hui", id: "1" },
                    { label: "3 Jours", id: "3" },
                    { label: "Semaine", id: "7" },
                ]}
                value={value}
                placeholder="Date"
                onChange={params => {
                    setValue(params.value)
                    props.dateSelected(params.value.map(v => v.id)[0])
                }}
                size={SIZE.compact}
            />
        </div>
    );
}
