import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { Redirect } from "react-router-dom";

/* Components */
import Navbar from "../shared/Navbar";
import ReservationsDash from "./reservations/ReservationsDash";
import ReservationDetails from "./reservations/ReservationDetails";
import Users from "../Users";

import { routes } from "../../utils/router";

class Dashboard extends Component {
  render() {
    return this.props.isLoggedIn ? (
      <div className="home-container">
        <Navbar />
        <div className="flex-row page-body">
          <div className="dashboard-content">
            <div className="main-card">
              <Switch>
                <Route
                  exact
                  path={routes.DASHBOARD}
                  component={ReservationsDash}
                />
                <Route
                  path={routes.ONE_RESERVATION}
                  component={ReservationDetails}
                />
                {this.props.isAdmin && (
                  <Route path={routes.USERS} component={Users} />
                )}
              </Switch>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Redirect to={routes.LOGIN} />
    );
  }
}

export default Dashboard;
