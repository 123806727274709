import React from "react";
import { Button } from "baseui/button";
import { Heading, HeadingLevel } from "baseui/heading";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import CustomModal from "../Modal";
import { User } from "../Form";
import UserTableList from "../UserTableList";
import LoadingComp from "../shared/LoadingComponent";

import {
  getUsers,
  postAddNewUser,
  generatePassword,
  passwordRegex,
  minRegex,
} from "../../utils/auth";
import { toast } from "react-toastify";

const Users = () => {
  const [loading, setLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    nextPage: null,
    prevPage: null,
    totalPages: 0,
    limit: 1,
  });
  const [docs, setDocs] = React.useState([]);

  const getUsersList = (lastPage) => {
    setLoading(true);
    getUsers(
      pagination.page,
      (data) => {
        const { page, totalPages, nextPage, prevPage, limit, docs } = data;
        setPagination({
          page: lastPage ? totalPages : page,
          totalPages,
          nextPage,
          prevPage,
          limit,
        });
        setDocs(docs);
        setLoading(false);
      },
      (msg) => {
        setLoading(false);
        toast.error(msg);
      }
    );
  };

  React.useEffect(() => {
    getUsersList();
  }, [pagination.page]);

  //const & fucntions for add user
  const [apiMsg, setApiMsg] = React.useState({ kind: "none", msg: "" });
  const initVal = {
    nom: "",
    prenom: "",
    email: "",
    password: generatePassword(),
  };

  const handelValidateAddUser = (values) => {
    const errors = {};

    if (!values.email) errors.email = "Champs obligatoire";
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }

    if (!values.nom.trim()) errors.nom = "Champs obligatoire";
    else if (values.nom.length < 4)
      errors.nom = `Le nom doit avoir minimum 4 caractères`;

    if (!values.prenom.trim()) errors.prenom = "Champs est obligatoire";
    else if (values.prenom.length < 4)
      errors.prenom = `Le prenom doit avoir minimum 4 caractères`;

    if (values.password.length < minRegex)
      errors.password = `Le mot de passe doit comporter minimum ${minRegex} caractères`;
    else if (!values.password.match(passwordRegex))
      errors.password = "Mot de passe doit être alphanumérique et/ou !?@#_$%&-";

    return errors;
  };

  const handelOnSubmitAddUser = async (values, { setSubmitting }) => {
    const user = await postAddNewUser(values, (error, msg) => {
      setApiMsg({ kind: error ? "neg" : "pos", msg });
      if (!error)
        setTimeout(() => {
          setApiMsg({ kind: "none", msg: "" });
          setIsOpen(false);
        }, 3000);
    });
    if (user) getUsersList(true);
    setSubmitting(false);
  };

  return (
    <div className="flex-row">
      <div className="toolbar-wrapper">
        <div className="toolbar">
          <Button
            onClick={() => setIsOpen(true)}
            startEnhancer={() => <i className="icon ion-md-person-add"></i>}
          >
            Ajouter un utilisateur
          </Button>
        </div>
      </div>
      <div className="flex-col list-wrapper">
        <div className="list-wrapper__container">
          <div className="flex-col">
            {loading ? (
              <LoadingComp />
            ) : (
              <FlexGrid
                className="main-tablist"
                flexGridColumnCount={1}
                flexGridColumnGap="scale800"
                flexGridRowGap="scale800"
                padding="0 1rem 1rem 1rem"
              >
                <FlexGridItem>
                  <HeadingLevel>
                    <Heading styleLevel={4}>Liste des utilisateurs</Heading>
                  </HeadingLevel>
                </FlexGridItem>
                <FlexGridItem>
                  <div className="list-wrapper">
                    <UserTableList
                      docs={docs}
                      setDocs={setDocs}
                      pagination={pagination}
                      setPagination={setPagination}
                    />
                  </div>
                </FlexGridItem>
              </FlexGrid>
            )}

            <CustomModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title="Ajouter un nouvel assistant"
              Form={() => (
                <User
                  setIsOpen={setIsOpen}
                  apiMsg={apiMsg}
                  initVal={initVal}
                  handelValidate={handelValidateAddUser}
                  handelOnSubmit={handelOnSubmitAddUser}
                />
              )}
            ></CustomModal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
