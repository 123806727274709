import axios from "axios";
import { getRefreshToken, setAccessToken, setRefreshToken } from "./auth";

export const URL_API = process.env.REACT_APP_URL_API;
export const URL_FILES = process.env.REACT_APP_URL_FILES;

export const axiosApiInstance = axios.create();

export const urls = {
  GET: URL_API + "reservations",
  // PATCH: URL_API + "reservations/",
  PATCH: URL_API + "agent/reservations/",
  VALIDATE: URL_API + "reservations/:id/validate",
  // GET_ONE: URL_API + "reservations/:id",
  GET_ONE: URL_API + "agent/reservations/:id",
  GET_STATES_COUNT: URL_API + "getReservationsCount",
  EXPORT: URL_API + "export",
  REFRESH: URL_API + "newTokens",
  AddNewUser: URL_API + "user/add",
  userPagination: URL_API + "user",
  userBlock: URL_API + "user/block",
  userUpdate: URL_API + "user/set",
  logoutUser: URL_API + "user/logout",
};

export const refreshAccessToken = async () => {
  let token = "";
  if (getRefreshToken()) {
    try {
      token = await axios({
        method: "post",
        url: urls.REFRESH,
        data: {
          refreshTocken: getRefreshToken(),
        },
      }).then((response) => {
        const { data } = response;
        setAccessToken(data.accessToken);
        setRefreshToken(data.refreshTocken);
        return data.accessToken;
      });
    } catch (error) {
      token = null;
    }
  }

  return token;
};
