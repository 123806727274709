/* eslint-disable default-case */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LoginForm from "./LoginForm/LoginForm";
import LoginImage from "./LoginImage/LoginImage";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="login-page-container">
        <LoginForm />
        <LoginImage />
      </div>
    );
  }
}

export default withRouter(Login);
