import React, { Component } from "react";

import { connect } from "react-redux";
import { setReservationsStatus } from "../../../redux/actions/reservationActions";

import { Tabs, Tab } from "baseui/tabs";
import Search from "baseui/icon/search";
import { Input, SIZE } from "baseui/input";
import { useStyletron } from "baseui";

import ReservationDateSelect from "./ReservationDateSelect";
import ReservationsList from "./ReservationsList";

import {
  RES_STATE_NEW,
  RES_STATE_VALID,
  RES_STATE_HISTORY,
  RES_STATE_ALL,
  RES_STATE_MISS_MATCH,
} from "../constReservations";

import { getReservationsCount } from "../../../utils/reservationsUtils";

const key_state = {
  0: RES_STATE_ALL,
  1: RES_STATE_NEW,
  2: RES_STATE_VALID,
  4: RES_STATE_HISTORY,
  3: RES_STATE_MISS_MATCH,
};

const Header = () => {
  const [css, theme] = useStyletron();
  return (
    <div
      style={{
        flex: 1,
        minHeight: "8vh",
        maxHeight: "8vh",
        paddingLeft: "30px",
      }}
    >
      <h3 style={{ color: theme.colors.buttonPrimaryFill }}>Dashboard</h3>
    </div>
  );
};

export class ReseravtionsDash extends Component {
  constructor() {
    super();

    this.dateSelected = this.dateSelected.bind(this);
    this.getReservationsCount = this.getReservationsCount.bind(this);
    this.updateCount = this.updateCount.bind(this);

    this.state = {
      date: null,
      value: "",
      activeKey: "",
      counts: {},
    };
  }

  componentDidMount() {
    this.setState({ activeKey: this.props.reservationStatus });
    this.getReservationsCount();
  }

  componentWillReceiveProps() {
    this.getReservationsCount();
  }

  getReservationsCount() {
    getReservationsCount((counts, code) => {
      if (code === 200) {
        this.setState({ counts: counts });
      }
    });
  }

  dateSelected(date) {
    this.setState({ date: date });
  }

  updateCount(count) {
    let counts = this.state.counts;
    counts[key_state[this.state.activeKey]] = count;
    this.setState({ counts: counts });
  }

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Header />
        <div className="toolbar-wrapper" style={{ flex: 1 }}>
          <div className="toolbar">
            <div className="cy-input">
              <Input
                value={this.state.value}
                onChange={(e) => this.setState({ value: e.target.value })}
                placeholder="Rechercher"
                startEnhancer={() => <Search />}
                size={SIZE.compact}
              />
            </div>
            <ReservationDateSelect dateSelected={this.dateSelected} />
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <Tabs
            activeKey={this.state.activeKey}
            onChange={({ activeKey }) => {
              this.props.setReservationsStatus(activeKey);
              this.setState({ activeKey: activeKey });
              // this.getReservationsCount();
            }}
          >
            <Tab
              title={"Toutes (" + (this.state.counts[RES_STATE_ALL] || 0) + ")"}
            >
              <ReservationsList
                status={RES_STATE_ALL}
                date={this.state.date}
                keyword={this.state.value}
                updateCount={this.updateCount}
                export={true}
              />
            </Tab>
            <Tab
              title={
                "En attente (" + (this.state.counts[RES_STATE_NEW] || 0) + ")"
              }
            >
              <ReservationsList
                status={RES_STATE_NEW}
                date={this.state.date}
                keyword={this.state.value}
                updateCount={this.updateCount}
              />
            </Tab>
            <Tab
              title={
                "À venir (" + (this.state.counts[RES_STATE_VALID] || 0) + ")"
              }
            >
              <ReservationsList
                status={RES_STATE_VALID}
                date={this.state.date}
                keyword={this.state.value}
                updateCount={this.updateCount}
              />
            </Tab>
            <Tab
              title={
                "Missmatch (" +
                (this.state.counts[RES_STATE_MISS_MATCH] || 0) +
                ")"
              }
            >
              <ReservationsList
                status={RES_STATE_MISS_MATCH}
                date={this.state.date}
                keyword={this.state.value}
                updateCount={this.updateCount}
              />
            </Tab>
            <Tab
              title={
                "Historique (" +
                (this.state.counts[RES_STATE_HISTORY] || 0) +
                ")"
              }
            >
              <ReservationsList
                status={RES_STATE_HISTORY}
                date={this.state.date}
                keyword={this.state.value}
                updateCount={this.updateCount}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reservationStatus: state.reservations.reservationStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setReservationsStatus: (data) => {
      dispatch(setReservationsStatus(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReseravtionsDash);
