import React, { Component } from "react";
import { FormControl } from "baseui/form-control";
import { Input, SIZE } from "baseui/input";

class CustomInput extends Component {
  render() {
    return (
      <FormControl
        label={<p className="input-label">{this.props.label.toUpperCase()}</p>}
        error={this.props.error}
      >
        <Input
          size={SIZE.large}
          overrides={{
            Input: {
              style: {
                backgroundColor: "#f7f9fc",
                borderRadius: "5px",
                borderWidth: "1px",
                borderColor: "#eef2f7",
              },
            },
            InputContainer: {
              style: {
                borderRadius: "5px",
                borderWidth: "1px",
                borderColor: "#eef2f7",
                height: "40px",
              },
            },
          }}
          placeholder={this.props.label}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          value={this.props.value}
          id={this.props.id}
          type={this.props.type || "text"}
          name={this.props.name}
        />
      </FormControl>
    );
  }
}

export default CustomInput;
