import React from "react";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ROLE,
  SIZE,
} from "baseui/modal";

const CustomModal = ({
  isOpen,
  setIsOpen,
  size,
  Form,
  title,
  Body,
  Footer,
  closeable,
}) => {
  return (
    <Modal
      onClose={() => setIsOpen(false)}
      closeable={closeable || false}
      isOpen={isOpen}
      animate
      autoFocus
      size={size ? size : SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>{title ? title : "No Title"}</ModalHeader>
      {Form ? (
        Form()
      ) : (
        <>
          <ModalBody>{Body && Body()}</ModalBody>
          <ModalFooter>{Footer && Footer()}</ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default CustomModal;
