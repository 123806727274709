import  React, {Component } from 'react';
import { Spinner } from 'baseui/spinner';

export default class LoadingComp extends Component {
    render() {
        return (
            < div className="loading-component"  >
                <div>
                    < h1 className="custom-title" > Loading data ... </h1>
                </div>
                < Spinner className="spinner" />
            </div>
        )
    }
}