import React from "react";
import { Pagination } from "baseui/pagination";
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell,
} from "baseui/table";
import { withStyle } from "baseui";
import { Checkbox, STYLE_TYPE } from "baseui/checkbox";
import CustomModal from "../Modal";
import { User } from "../Form";

import { blockUser, passwordRegex, minRegex } from "../../utils/auth";
import { toast } from "react-toastify";
import { updateUser } from "../../utils/auth";

const cssProps = (w) => {
  return {
    width: w,
    minWidth: w,
    maxWidth: w,
    //border:"solid"
  };
};

const overrides = (user) => {
  return {
    Toggle: {
      style: ({ $theme }) => {
        return {
          backgroundColor: $theme.colors.primaryB,
          height: "15px",
          width: "15px",
        };
      },
    },
    ToggleTrack: {
      style: ({ $theme }) => {
        return {
          backgroundColor: user.isBlocked
            ? $theme.colors.negative
            : $theme.colors.positive300,
          height: "20px",
          width: "33px",
          borderRadius: "20px",
          paddingLeft: "4px",
        };
      },
    },
  };
};

const CustomCell = withStyle(StyledCell, {
  paddingTop: "0",
  paddingBottom: "0",
});

const UserTableList = ({ setDocs, docs, setPagination, pagination }) => {
  const updateIsBlockedValue = (user, index, value) => {
    user.isBlocked = value;
    docs.splice(index, 1);
    docs.splice(index, 0, { ...user });
    setDocs([...docs]);
  };

  const handelCheckboxOnchange = (e, user, index) => {
    updateIsBlockedValue(user, index, e.currentTarget.checked);
    blockUser(user, (msg) => {
      updateIsBlockedValue(user, index, !user.isBlocked);
      toast.error(msg);
    });
  };

  //const & functions for edit user
  const [isOpen, setIsOpen] = React.useState(false);
  const [apiMsg, setApiMsg] = React.useState({ kind: "none", msg: "" });
  const [initVal, setInitVal] = React.useState({
    id: "",
    nom: "",
    prenom: "",
    email: "",
    password: "",
    index: -1,
  });

  const handelValidateSetUser = (values) => {
    const errors = {};

    if (!values.email) errors.email = "Champs obligatoire";
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }

    if (!values.nom.trim()) errors.nom = "Champs obligatoire";
    else if (values.nom.length < 4)
      errors.nom = `Le nom doit avoir minimum 4 caractères`;

    if (!values.prenom.trim()) errors.prenom = "Champs est obligatoire";
    else if (values.prenom.length < 4)
      errors.prenom = `Le prenom doit avoir minimum 4 caractères`;

    if (values.password.length > 0) {
      if (values.password.length < minRegex)
        errors.password = `Le mot de passe doit comporter minimum ${minRegex} caractères`;
      else if (!values.password.match(passwordRegex))
        errors.password =
          "Mot de passe doit être alphanumérique et/ou !?@#_$%&-";
    }

    return errors;
  };

  const handelOnSubmitSetUser = async (values, { setSubmitting }) => {
    if (
      values.email.toLowerCase().trim() !== initVal.email.trim() ||
      values.prenom.toLowerCase().trim() !== initVal.prenom.trim() ||
      values.nom.toLowerCase().trim() !== initVal.nom.trim() ||
      values.password
    ) {
      const result = await updateUser(values, (error, msg) => {
        setApiMsg({ kind: error ? "neg" : "pos", msg });
        if (!error)
          setTimeout(() => {
            setApiMsg({ kind: "none", msg: "" });
            setIsOpen(false);
          }, 3000);
      });
      if (result) applyUpdates(result.data.value);
    }

    setSubmitting(false);
  };

  const applyUpdates = (newVal) => {
    if (initVal.index !== -1) {
      const user = docs[initVal.index];

      user.email = newVal.email;
      user.prenom = newVal.prenom;
      user.nom = newVal.nom;

      docs.splice(initVal.index, 1);
      docs.splice(initVal.index, 0, { ...user });
      setDocs([...docs]);
    }
  };

  const openModalSetUser = (user, index) => {
    const { _id: id, nom, prenom, email } = user;
    setInitVal({ id, nom, prenom, email, password: "", index });
    setIsOpen(true);
  };

  return (
    <>
      <div className="list-wrapper">
        <div className="list-container">
          <StyledTable>
            <StyledHead>
              <StyledHeadCell style={cssProps("1%")}>#</StyledHeadCell>
              <StyledHeadCell>Prenom</StyledHeadCell>
              <StyledHeadCell>Nom</StyledHeadCell>
              <StyledHeadCell>Email</StyledHeadCell>
              <StyledHeadCell>Role</StyledHeadCell>
              <StyledHeadCell>Action</StyledHeadCell>
              <StyledHeadCell>Bloquer</StyledHeadCell>
            </StyledHead>
            <StyledBody>
              {docs.map((user, key) => {
                return (
                  <StyledRow key={key} className="reservation-row">
                    <CustomCell style={cssProps("1%")}>
                      {(pagination.page - 1) * pagination.limit + key + 1}
                    </CustomCell>
                    <CustomCell>{user.prenom}</CustomCell>
                    <CustomCell>{user.nom}</CustomCell>
                    <CustomCell>{user.email}</CustomCell>

                    <CustomCell>
                      {user.role === 1 ? "administrateur" : "Assistant"}
                    </CustomCell>
                    <CustomCell onClick={() => openModalSetUser(user, key)}>
                      <p>
                        <u>
                          <i className="icon ion-md-create"></i> Modifier
                        </u>
                      </p>
                    </CustomCell>
                    <CustomCell>
                      <Checkbox
                        checked={user.isBlocked}
                        onChange={(e) => handelCheckboxOnchange(e, user, key)}
                        checkmarkType={STYLE_TYPE.toggle_round}
                        overrides={overrides(user)}
                      />
                    </CustomCell>
                  </StyledRow>
                );
              })}
            </StyledBody>
          </StyledTable>
        </div>
      </div>
      <div className="custom-pagination">
        <Pagination
          currentPage={pagination.page}
          numPages={pagination.totalPages}
          onPrevClick={() => {
            if (pagination.prevPage)
              setPagination({
                ...pagination,
                page: pagination.prevPage,
              });
          }}
          onNextClick={() => {
            if (pagination.nextPage)
              setPagination({
                ...pagination,
                page: pagination.nextPage,
              });
          }}
          labels={{
            prevButton: " ",
            nextButton: " ",
            preposition: "sur",
          }}
        />
      </div>

      <CustomModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Modifier assistant"
        closeable={true}
        Form={() => (
          <User
            passwordLabel="Nouveau mot de passe"
            submitLabel="Modifier"
            setIsOpen={setIsOpen}
            apiMsg={apiMsg}
            initVal={initVal}
            handelValidate={handelValidateSetUser}
            handelOnSubmit={handelOnSubmitSetUser}
          />
        )}
      ></CustomModal>
    </>
  );
};

export default UserTableList;
