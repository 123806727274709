/* React */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { format } from "date-fns";

/* Baseui */
import { Pagination } from "baseui/pagination";

import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell,
} from "baseui/table";
import { Tag } from "baseui/tag";
import { withStyle } from "baseui";

/* Utils & Redux */
import {
  window,
  handlePageChange,
  LIMIT,
} from "../../../utils/tableUtils/pagination";
// import { setNewReservations }  from '../../../redux/actions/reservationActions';
import { wrappedDiv, MapDeviceMeta } from "../../../utils/shared";

import {
  RES_STATE_NEW,
  RES_STATE_HISTORY,
  RES_STATE_ALL,
  RES_STATUS,
  statusToTagKind,
  RES_STATE_VALID,
} from "../constReservations";

const CenteredBodyCell = withStyle(StyledCell, {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "0",
  paddingBottom: "0",
});

const cssProps = (w) => {
  return {
    width: w,
    minWidth: w,
    maxWidth: w,
    //border:"solid"
  };
};
const TBfontSize = "13px";

class ReservationsTable extends Component {
  constructor(props) {
    super();

    this.handleReservation = this.handleReservation.bind(this);

    const status = parseInt(props.status);

    this.state = {
      page: 1,
      limit: LIMIT,
      status: status,
      showStatus: [RES_STATE_ALL, RES_STATE_HISTORY].includes(status),
      showDate: status !== RES_STATE_NEW,
      showAgent: status !== RES_STATE_NEW,
      showReserved: status === RES_STATE_NEW,
    };
  }

  componentDidMount() {
    // console.log("props ", this.props, this.state.limit)
    let total = this.props.reservations.length;

    if (total > 0 && total <= this.state.limit) {
      this.setState({ limit: total });
    } else if (this.state.limit < LIMIT) {
      this.setState({ limit: LIMIT });
    }
  }

  handleReservation(row) {
    this.props.handleReservation(row);
  }

  render() {
    // console.log("state", this.state)
    let reservationsData = this.props.reservations;

    return (
      <div className="list-wrapper">
        <div className="list-container">
          <StyledTable>
            <StyledHead>
              <StyledHeadCell
                style={{
                  ...cssProps("4%"),
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                {wrappedDiv("#", { paddingLeft: "1em", paddingRight: "1em" })}
              </StyledHeadCell>
              <StyledHeadCell
                style={{
                  ...cssProps("10%"),
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                {wrappedDiv("Code", {
                  paddingLeft: "1em",
                  paddingRight: "1em",
                })}
              </StyledHeadCell>
              <StyledHeadCell
                style={{
                  ...cssProps("12%"),
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                {wrappedDiv("Nom et Prénom", {
                  paddingLeft: "1em",
                  paddingRight: "1em",
                })}
              </StyledHeadCell>
              <StyledHeadCell
                style={{
                  ...cssProps("11%"),
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                {wrappedDiv("Téléphone", {
                  paddingLeft: "1em",
                  paddingRight: "1em",
                })}
              </StyledHeadCell>
              {this.state.showDate && (
                <StyledHeadCell
                  style={{
                    ...cssProps("11%"),
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  {wrappedDiv("Date et Heure", {
                    paddingLeft: "1em",
                    paddingRight: "1em",
                  })}
                </StyledHeadCell>
              )}
              <StyledHeadCell
                style={{
                  ...cssProps("10%"),
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                {wrappedDiv("Adresse", {
                  paddingLeft: "1em",
                  paddingRight: "1em",
                })}
              </StyledHeadCell>
              {this.state.showReserved && (
                <StyledHeadCell
                  style={{
                    ...cssProps("15%"),
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  {wrappedDiv("Date de la Demande", {
                    paddingLeft: "1em",
                    paddingRight: "1em",
                  })}
                </StyledHeadCell>
              )}
              {this.state.showStatus && (
                <StyledHeadCell
                  style={{
                    ...cssProps("8%"),
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  {wrappedDiv("Status", {
                    paddingLeft: "1em",
                    paddingRight: "1em",
                  })}
                </StyledHeadCell>
              )}
              {this.state.showAgent && (
                <StyledHeadCell
                  style={{
                    ...cssProps("9%"),
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  {wrappedDiv("Traitée par", {
                    paddingLeft: "1em",
                    paddingRight: "1em",
                  })}
                </StyledHeadCell>
              )}
              {this.state.showAgent && (
                <StyledHeadCell
                  style={{
                    ...cssProps("8%"),
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  {wrappedDiv("Traitée le", {
                    paddingLeft: "1em",
                    paddingRight: "1em",
                  })}
                </StyledHeadCell>
              )}
              <StyledHeadCell
                style={{
                  ...cssProps("15%"),
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                {wrappedDiv("Matériels utilisateur", {
                  paddingLeft: "1em",
                  paddingRight: "1em",
                })}
              </StyledHeadCell>
            </StyledHead>
            <StyledBody>
              {window(reservationsData, this).map((row, index) => (
                <StyledRow
                  key={row.reservation_id}
                  className="reservation-row"
                  onClick={() => this.handleReservation(row)}
                >
                  <StyledCell
                    key={0}
                    style={{
                      ...cssProps("4%"),
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
                      {(this.state.page - 1) * this.state.limit + index + 1}
                    </div>
                  </StyledCell>
                  <StyledCell
                    key={1}
                    style={{
                      ...cssProps("10%"),
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    {wrappedDiv(row.reservation_id, {
                      fontSize: TBfontSize,
                      paddingLeft: "1em",
                      paddingRight: "1em",
                    })}
                  </StyledCell>
                  <StyledCell
                    key={2}
                    style={{
                      ...cssProps("12%"),
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    {wrappedDiv(row.nom.toUpperCase() + " " + row.prenom, {
                      fontSize: TBfontSize,
                      paddingLeft: "1em",
                      paddingRight: "1em",
                    })}
                  </StyledCell>
                  <StyledCell
                    key={3}
                    style={{
                      ...cssProps("11%"),
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    {wrappedDiv(row.phone, {
                      fontSize: TBfontSize,
                      paddingLeft: "1em",
                      paddingRight: "1em",
                    })}
                  </StyledCell>

                  {this.state.showDate && (
                    <StyledCell
                      key={4}
                      style={{
                        ...cssProps("11%"),
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    >
                      {wrappedDiv(
                        row.slots[0]
                          ? format(new Date(row.slots[0].date), "dd/MM/yyyy") +
                              " " +
                              row.slots[0].heure
                          : "-",
                        {
                          fontSize: TBfontSize,
                          paddingLeft: "1em",
                          paddingRight: "1em",
                        }
                      )}
                    </StyledCell>
                  )}
                  <StyledCell
                    key={5}
                    style={{
                      ...cssProps("10%"),
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    {wrappedDiv(
                      row.commune
                        ? row.commune.nom + ", " + row.commune.wilaya_id.nom
                        : " - ",
                      {
                        fontSize: TBfontSize,
                        paddingLeft: "1em",
                        paddingRight: "1em",
                      }
                    )}
                  </StyledCell>

                  {this.state.showReserved && (
                    <StyledCell
                      key={6}
                      style={{
                        ...cssProps("15%"),
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    >
                      {wrappedDiv(
                        format(new Date(row.created_at), "dd/MM/yyyy - HH:mm"),
                        {
                          fontSize: TBfontSize,
                          paddingLeft: "1em",
                          paddingRight: "1em",
                        }
                      )}
                    </StyledCell>
                  )}

                  {this.state.showStatus && (
                    <CenteredBodyCell
                      key={7}
                      style={{
                        ...cssProps("8%"),
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    >
                      <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
                        {row.state == RES_STATE_VALID && row.isMissmatch ? (
                          <Tag
                            closeable={false}
                            variant="outlined"
                            kind="positive"
                          >
                            {wrappedDiv("Missmatch", { fontSize: "11px" })}
                          </Tag>
                        ) : (
                          <Tag
                            closeable={false}
                            variant="outlined"
                            kind={statusToTagKind(row.state)}
                          >
                            {wrappedDiv(RES_STATUS[row.state], {
                              fontSize: "11px",
                            })}
                          </Tag>
                        )}
                      </div>
                    </CenteredBodyCell>
                  )}
                  {this.state.showAgent && (
                    <StyledCell
                      key={8}
                      style={{
                        ...cssProps("9%"),
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    >
                      {wrappedDiv(row.agentFullName, {
                        fontSize: TBfontSize,
                        paddingLeft: "1em",
                        paddingRight: "1em",
                      })}
                    </StyledCell>
                  )}
                  {this.state.showAgent && (
                    <StyledCell
                      key={9}
                      style={{
                        ...cssProps("8%"),
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    >
                      {wrappedDiv(row.dateUpdate, {
                        fontSize: TBfontSize,
                        paddingLeft: "1em",
                        paddingRight: "1em",
                      })}
                    </StyledCell>
                  )}
                  <StyledCell
                    key={10}
                    style={{
                      ...cssProps("15%"),
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        paddingLeft: "1em",
                        paddingRight: "1em",
                      }}
                    >
                      {MapDeviceMeta(row.deviceMeta)}
                    </div>
                  </StyledCell>
                </StyledRow>
              ))}
            </StyledBody>
          </StyledTable>
        </div>
        <div className="custom-pagination">
          <Pagination
            currentPage={this.state.page}
            numPages={Math.ceil(reservationsData.length / this.state.limit)}
            onPageChange={({ nextPage }) =>
              handlePageChange(nextPage, reservationsData, this)
            }
            labels={{
              prevButton: " ",
              nextButton: " ",
              preposition: "sur",
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reservations: state.reservations.reservations.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReservationsTable));
