import * as React from "react";
import { Datepicker } from "baseui/datepicker";

export default ({dates, setDates}) => {
  return (
    <Datepicker
      value={dates}
      onChange={({ date }) => {
        setDates(Array.isArray(date) ? date : [date])
        }
      }
      range
      clearable
    />
  );
}