import React, { Fragment, Component } from "react";
import { withRouter } from "react-router-dom";

/* Baseui */
import { KIND, SIZE, Button } from "baseui/button";
import { Tag } from "baseui/tag";

import ArrowLeft from "baseui/icon/arrow-left";
import Check from "baseui/icon/check";
import Delete from "baseui/icon/delete";
import CheckIndeterminate from "baseui/icon/check-indeterminate";

import { Card, StyledBody, StyledAction } from "baseui/card";
import { Heading, HeadingLevel } from "baseui/heading";

import { format } from "date-fns";

import ConfirmationDialog from "../../shared/ConfirmationDialog";
import ReservationInfosBox from "./ReservationInfosBox";
import ReservationValidationForm from "./ReservationValidationForm";

import {
  RES_STATE_CANCELED,
  RES_STATE_NEW,
  RES_STATE_VALID,
  RES_STATUS,
  statusToTagKind,
} from "../constReservations";

import {
  getSingleReservation,
  patchReservation,
  validateReservation,
} from "../../../utils/reservationsUtils";

import { MapDeviceMeta } from "../../../utils/shared";

const confirmationDialogText = {
  CANCEL: {
    title: "Confirmation d'annulation de la réservation",
    message: "Êtes-vous sûr de vouloir annuler cette réservation? ",
  },
};

export class ReservationDetails extends Component {
  constructor(props) {
    super(props);

    let id = props.match.params.id;

    this.state = {
      id: id,
      reservation: null,

      validationData: null,

      isOpenConfirmationDialog: false,
      confirmationDialogTitle: "",
      confirmationDialogMessage: "",

      patchAction: "",
      btnValiderIsLoading: false,
      btnCancelIsLoading: false,
      btnFinishIsLoading: false,
      btnAbsentIsLoading: false,
    };

    this.updateFormData = this.updateFormData.bind(this);
    this.goBack = this.goBack.bind(this);
    this.cancelReservation = this.cancelReservation.bind(this);
    this.closeConfirmationDialog = this.closeConfirmationDialog.bind(this);
    this.patchReservation = this.patchReservation.bind(this);
    this.validateReservation = this.validateReservation.bind(this);
    this.finishReservation = this.finishReservation.bind(this);
    this.clientAbsent = this.clientAbsent.bind(this);
  }

  componentWillMount() {
    getSingleReservation(this.state.id, (reservation, code) => {
      if (code === 200) {
        this.setState({ reservation: reservation });
      }
    });
  }

  goBack() {
    this.props.history.goBack();
  }

  updateFormData(slot, date) {
    if (slot !== null) {
      this.setState({
        validationData: { slot_num: slot },
      });
    } else if (date !== null) {
      this.setState({
        validationData: { suggested_date: (new Date(date) + "UTC").toString() },
      });
    } else {
      this.setState({
        validationData: null,
      });
    }
    // console.log('in parent', data)
  }

  cancelReservation() {
    // console.log('action', action, this.state.singleReservation.reservation_id)
    this.setState({ btnCancelIsLoading: true });
    let action = "CANCEL";
    this.setState({
      isOpenConfirmationDialog: true,
      confirmationDialogTitle: confirmationDialogText[action].title,
      confirmationDialogMessage: confirmationDialogText[action].message,
      patchAction: action,
      btnCancelIsLoading: true,
    });
  }

  clientAbsent() {
    this.setState({ btnAbsentIsLoadin: true });
    this.patchReservation("ABSENT");
  }

  finishReservation() {
    this.setState({ btnFinishIsLoading: true });
    this.patchReservation("FINISH");
  }

  patchReservation(action) {
    // console.log(id, action)

    const that = this;
    patchReservation(
      this.state.reservation.reservation_id,
      action,
      (reservation, code) => {
        if (code === 200) {
          this.goBack();
        } else {
          if (code === 400) {
            //Show error message
            //Impossible to 'action' reservation 'id'
          } else {
            that.setState({ errCnx: true });
          }
        }
        this.setState({
          btnCancelIsLoading: false,
          btnFinishIsLoading: false,
        });
      }
    );
  }

  closeConfirmationDialog(action) {
    // console.log(this.state.patchAction.id)
    if (action) {
      this.patchReservation(this.state.patchAction);
    }
    this.setState({
      // patchAction: null,
      isOpenConfirmationDialog: false,
      confirmationDialogTitle: null,
      confirmationDialogMessage: null,
      btnCancelIsLoading: false,
    });
  }

  validateReservation() {
    this.setState({ btnValiderIsLoading: true });
    // console.log('validateReservation', this.state.validationData)
    validateReservation(
      this.state.id,
      this.state.validationData,
      (reservation, code) => {
        // console.log(reservation)
        if (code === 200) {
          this.goBack();
        }
      }
    );
  }

  render() {
    if (this.state.reservation)
      this.state.reservation.accepted = this.state.reservation.slots.find(
        (x) => x.state == 2
      );

    return (
      <Fragment>
        {this.state.reservation ? (
          <Card className="reservation-details-card">
            <StyledBody>
              <HeadingLevel>
                <Heading styleLevel={5} className="heading">
                  <div className="heading-content">
                    <div className="heading-content-left">
                      {
                        <ArrowLeft
                          size={36}
                          className="return-icon"
                          onClick={this.goBack}
                        />
                      }
                      Réservation #{this.state.reservation.reservation_id}
                    </div>
                    <div className="heading-content-center">{MapDeviceMeta(this.state.reservation.deviceMeta)}</div>
                    <div className="heading-content-right">
                      {this.state.reservation.isMissmatch ? (
                        <Tag
                          closeable={false}
                          variant="outlined"
                          kind="negative"
                        >
                          Missmatch
                        </Tag>
                      ) : (
                        ""
                      )}
                      <Tag
                        closeable={false}
                        variant="outlined"
                        kind={statusToTagKind(this.state.reservation.state)}
                      >
                        {RES_STATUS[this.state.reservation.state]}
                      </Tag>
                    </div>
                  </div>
                </Heading>
              </HeadingLevel>
              <ReservationInfosBox
                reservation={this.state.reservation}
              ></ReservationInfosBox>
              {this.state.reservation.state !== RES_STATE_NEW
                ? this.state.reservation.accepted && (
                    <div className="info-item">
                      <div className="info-item-date">
                        <p className="info-item-label">Date de l'examen</p>
                        <p className="info-text">
                          {format(
                            new Date(this.state.reservation.accepted.date),
                            "dd/MM/yyyy"
                          ) +
                            " à " +
                            this.state.reservation.accepted.heure}
                        </p>
                      </div>
                    </div>
                  )
                : this.state.reservation.state !== RES_STATE_CANCELED && (
                    <ReservationValidationForm
                      slots={this.state.reservation.slots}
                      updateFormData={this.updateFormData}
                    />
                  )}
            </StyledBody>
            <StyledAction className="actions">
              {[RES_STATE_VALID].includes(this.state.reservation.state) ? (
                <Button
                  onClick={this.clientAbsent}
                  startEnhancer={() => <CheckIndeterminate size={24} />}
                  size={SIZE.compact}
                  kind={KIND.secondary}
                  className="btn-ml-10"
                  isLoading={this.state.btnAbsentIsLoading}
                >
                  {" "}
                  RDV Non honoré
                </Button>
              ) : (
                ""
              )}
              {[RES_STATE_NEW, RES_STATE_VALID].includes(
                this.state.reservation.state
              ) ? (
                <Fragment>
                  <Button
                    onClick={this.cancelReservation}
                    startEnhancer={() => <Delete size={24} />}
                    size={SIZE.compact}
                    kind={KIND.secondary}
                    className="btn-ml-10"
                    isLoading={this.state.btnCancelIsLoading}
                  >
                    {" "}
                    Annuler la réservation
                  </Button>
                </Fragment>
              ) : (
                ""
              )}
              {this.state.reservation.state === RES_STATE_NEW ? (
                <Button
                  onClick={this.validateReservation}
                  size={SIZE.compact}
                  disabled={!this.state.validationData}
                  startEnhancer={() => <Check size={24} />}
                  className="btn-ml-10"
                  isLoading={this.state.btnValiderIsLoading}
                  // autoFocus
                >
                  Valider l'examen
                </Button>
              ) : this.state.reservation.state === RES_STATE_VALID ? (
                <Button
                  onClick={this.finishReservation}
                  size={SIZE.compact}
                  className="btn-ml-10"
                  startEnhancer={() => <Check size={24} />}
                  // autoFocus
                  isLoading={this.state.btnFinishIsLoading}
                >
                  Examen terminé
                </Button>
              ) : (
                <Button
                  onClick={this.goBack}
                  className="btn-ml-10 btn-150"
                  size={SIZE.compact}
                  // autoFocus
                >
                  OK
                </Button>
              )}
            </StyledAction>
          </Card>
        ) : (
          ""
        )}

        {this.state.isOpenConfirmationDialog ? (
          <ConfirmationDialog
            title={this.state.confirmationDialogTitle}
            message={this.state.confirmationDialogMessage}
            closeConfirmationDialog={this.closeConfirmationDialog}
          />
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

export default withRouter(ReservationDetails);
