import React, { Component } from 'react';

import logo from '../../../images/Logo-CIMA.png'

class CompanyLogo extends Component {
    render() {
        return (
            <div className="logo-container">
                <img src={logo} alt="CIMA Yaker logo"/>
            </div>
        );
    }
}

export default CompanyLogo;