/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
import axios from "axios";
import { URL_API, axiosApiInstance, urls } from "./apiUtils";

const ACCESS_TOKEN = "ACCESS_TOKEN";
const REFRESH_TOKEN = "REFRESH_TOKEN";
const USER_ROLE = "USER_ROLE";
const USER_NOM = "USER_NOM";
const USER_PRENOM = "USER_PRENOM";

export const login = (email, password, callback) => {
  axios({
    url: URL_API + "login/agent",
    method: "post",
    data: { email: email, password: password },
  })
    .then((response) => {
      if (response.status === 200) {
        saveLogInInfo(response.data.agent);
        callback(200, "ok", response.data.agent);
      }
    })
    .catch((err) => {
      if (err === "Error: Network Error") {
        // network error
        callback(503);
      } else {
        const error_code = err.response ? err.response.status : 400;
        callback(
          error_code,
          err.response
            ? err.response.data.message
            : "Une erreur est survenue, veuillez réessayer svp ! "
        );
      }
    });
};

export const postAddNewUser = async (data, notify) => {
  try {
    const result = await axiosApiInstance({
      url: urls.AddNewUser,
      method: "post",
      headers: {
        Authorization: "Bearer " + getAccessToken(),
      },
      data,
    });

    if (result.status === 201) {
      notify(false, result.data.message);
      return result.data.user;
    }
  } catch (error) {
    let errorMsg = "Une erreur est survenue, veuillez réessayer svp !";
    if (error.response && error.response.status !== 401) {
      errorMsg = error.response.data.message;
    }
    notify(true, errorMsg);
  }
  return null;
};

export async function getUsers(page, success, showError) {
  try {
    const result = await axiosApiInstance({
      url: urls.userPagination,
      method: "get",
      headers: {
        Authorization: "Bearer " + getAccessToken(),
      },
      params: {
        page,
      },
    });

    if (result.status === 200) {
      success(result.data);
    }
  } catch (error) {
    let errorMsg = "Une erreur est survenue, veuillez réessayer svp !";
    if (error.response && error.response.status !== 401) {
      errorMsg = error.response.data.message;
    }
    return showError(errorMsg);
  }
}

export const blockUser = async ({ _id, isBlocked }, notify) => {
  try {
    await axiosApiInstance({
      url: urls.userBlock,
      method: "post",
      headers: {
        Authorization: "Bearer " + getAccessToken(),
      },
      data: {
        id: _id,
        isBlocked,
      },
    });
  } catch (error) {
    notify("Une erreur est survenue, veuillez réessayer svp !");
  }
};

export const updateUser = async (values, notify) => {
  const { id, email, nom, prenom, password } = values;
  try {
    const result = await axiosApiInstance({
      url: urls.userUpdate,
      method: "post",
      headers: {
        Authorization: "Bearer " + getAccessToken(),
      },
      data: {
        id,
        email,
        nom,
        prenom,
        password,
      },
    });
    notify(false, "Vos modifications ont été bien enregistrées");
    return result;
  } catch (error) {
    let errorMsg = "Une erreur est survenue, veuillez réessayer svp !";
    if (error.response && error.response.status !== 401) {
      errorMsg = error.response.data.message;
    }
    notify(true, errorMsg);
  }
  return null;
};

const setRole = (role) => {
  localStorage.setItem(USER_ROLE, role);
};

const getRole = () => {
  try {
    return parseInt(localStorage.getItem(USER_ROLE));
  } catch (error) {
    return 0;
  }
};

const setNom = (nom) => {
  localStorage.setItem(USER_NOM, nom);
};

const getNom = () => {
  return localStorage.getItem(USER_NOM);
};

const setPrenom = (prenom) => {
  localStorage.setItem(USER_PRENOM, prenom);
};

const getPrenom = () => {
  return localStorage.getItem(USER_PRENOM);
};

export const setAccessToken = (token) => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const setRefreshToken = (token) => {
  localStorage.setItem(REFRESH_TOKEN, token);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

const saveLogInInfo = ({ nom, prenom, role, accessToken, refreshTocken }) => {
  setNom(nom);
  setPrenom(prenom);
  setRole(role);
  setAccessToken(accessToken);
  setRefreshToken(refreshTocken);
};

export const logout = async () => {
  if (getRefreshToken()) {
    await axios({
      url: urls.logoutUser,
      method: "post",
      data: {
        refreshTocken: getRefreshToken(),
      },
    });
  }
  setNom("");
  setPrenom("");
  setRole("");
  setAccessToken("");
  setRefreshToken("");
};

export const getUserInfo = () => {
  return {
    isLoggedIn: getAccessToken() ? true : false,
    nom: getNom(),
    prenom: getPrenom(),
    role: getRole(),
  };
};

//############################# generate password   ################################

export const minRegex = 8;
const maxRegex = 30;
export const passwordRegex = new RegExp(
  `[a-zA-Z0-9!?@#_$%&-]{${minRegex},${maxRegex}}$`
);

const minPasswordLength = 10;
const maxPasswordLength = 12;

const getRandomNumberBetween = (min, max) =>
  Math.floor(Math.random() * (max - min) + min);

function getRandomUpperCase() {
  return String.fromCharCode(getRandomNumberBetween(26, 0) + 65);
}

function getRandomLowerCase() {
  return String.fromCharCode(getRandomNumberBetween(26, 0) + 97);
}

function getRandomNumber() {
  return String.fromCharCode(getRandomNumberBetween(10, 0) + 48);
}

function getRandomSymbol() {
  const symbol = "!?@#_$%&-";
  const i = getRandomNumberBetween(symbol.length, 0);
  return symbol[i];
}

const randomFunc = [
  getRandomUpperCase,
  getRandomLowerCase,
  getRandomNumber,
  getRandomSymbol,
];

function getRandomFunc() {
  const i = getRandomNumberBetween(randomFunc.length, 0);
  return randomFunc[i];
}

export function generatePassword() {
  let password = "";
  const passwordLength =
    Math.random() * (maxPasswordLength - minPasswordLength) + minPasswordLength;
  for (let i = 1; i <= passwordLength; i++) password += getRandomFunc()();

  if (!password.match(passwordRegex)) return generatePassword();
  return password;
}
