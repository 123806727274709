import React from "react";

import { Formik } from "formik";
import { ModalBody, ModalFooter, ModalButton } from "baseui/modal";
import { Button } from "baseui/button";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import CustomInput from "../login/CustomInput";
import { generatePassword } from "../../utils/auth";
import { Notification, KIND } from "baseui/notification";

const User = ({
  setIsOpen,
  apiMsg,
  initVal,
  handelValidate,
  handelOnSubmit,
  passwordLabel,
  submitLabel,
}) => {
  return (
    <div>
      {apiMsg.kind !== "none" && (
        <Notification
          overrides={{
            Body: { style: { width: "auto", margin: "0 30px 0 30px " } },
          }}
          kind={apiMsg.kind === "neg" ? KIND.negative : KIND.positive}
        >
          {apiMsg.msg}
        </Notification>
      )}
      <Formik
        initialValues={initVal}
        validate={handelValidate}
        onSubmit={handelOnSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setValues,
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <ModalBody>
              <CustomInput
                type="email"
                name="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && errors.email ? errors.email : ""}
              />
              <FlexGrid flexGridColumnCount={2} justifyContent="space-between">
                <FlexGridItem>
                  <CustomInput
                    name="nom"
                    label="Nom"
                    value={values.nom}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.nom && errors.nom ? errors.nom : ""}
                  />
                </FlexGridItem>
                <FlexGridItem>
                  <CustomInput
                    name="prenom"
                    label="Prenom"
                    value={values.prenom}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.prenom && errors.prenom ? errors.prenom : ""}
                  />
                </FlexGridItem>
              </FlexGrid>
              <FlexGrid
                flexGridColumnGap="scale800"
                flexGridRowGap="scale800"
                marginBottom="scale800"
                flexGridColumnCount={3}
              >
                <FlexGridItem
                  overrides={{
                    Block: {
                      style: ({ $theme }) => ({
                        width: `calc((200% - ${$theme.sizing.scale800}) / 3)`,
                      }),
                    },
                  }}
                >
                  <CustomInput
                    label={passwordLabel ? passwordLabel : "Mot de passe"}
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.password && errors.password ? errors.password : ""
                    }
                  />
                </FlexGridItem>
                <FlexGridItem display="none">
                  This invisible one is needed so the margins line up
                </FlexGridItem>
                <FlexGridItem
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  marginTop="25px"
                >
                  <Button
                    type="button"
                    size="compact"
                    onClick={() =>
                      setValues({ ...values, password: generatePassword() })
                    }
                  >
                    Générer
                  </Button>
                </FlexGridItem>
              </FlexGrid>
            </ModalBody>
            <ModalFooter>
              <ModalButton type="submit" isLoading={isSubmitting}>
                {submitLabel || "Ajouter"}
              </ModalButton>
              <ModalButton type="button" onClick={() => setIsOpen(false)}>
                Annuler
              </ModalButton>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default User;
