import React, { Component } from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import { Button } from "baseui/button";

import logo from "../../images/Logo-CIMA.png";
import { logout } from "../../utils/auth";
import { doLogout } from "../../redux/actions/userActions";

import { routes } from "../../utils/router";

class Navbar extends Component {
  goHome() {
    this.props.history.push(routes.DASHBOARD);
  }

  handeLogout() {
    logout();
    this.props.logout();
  }

  render() {
    return (
      <div className="navbar">
        <div className="navbar-container">
          <div className="navbar-container__img" onClick={() => this.goHome()}>
            <img src={logo} alt="Logo cima yaker" />
          </div>
          <div
            className="navbar-container__btn"
            style={{ flex: 1, justifyContent: "flex-end" }}
          >
            {this.props.user.role === 1 && (
              <>
                <Button
                  onClick={() => this.props.history.push(routes.DASHBOARD)}
                  startEnhancer={() => <i className="icon ion-md-calendar"></i>}
                >
                  Gestion des rendez-vous
                </Button>
                <Button
                  onClick={() => this.props.history.push(routes.USERS)}
                  startEnhancer={() => <i className="icon ion-ios-people"></i>}
                >
                  Gestion des utilisateurs
                </Button>
              </>
            )}

            <Button
              onClick={() => this.handeLogout()}
              startEnhancer={() => <i className="icon ion-md-log-out"></i>}
            >
              Se déconnecter
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(doLogout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
