import React, { Component } from 'react';
import {Button,SIZE} from 'baseui/button';
import ArrowRight from 'baseui/icon/arrow-right';

class SubmitBtn extends Component {
    render() {
        return (
            <div className="submit-btn-container">
                <Button
                    type="submit"
                    size={SIZE.compact}
                    endEnhancer={() => <ArrowRight size={24}/>}
                    // onClick={this.props.onClick}
                    disabled= {this.props.disabled}
                    isLoading= {this.props.disabled}
                    overrides={{
                        BaseButton: {
                            style: {
                                borderRadius: "5px",
                                // backgroundColor: "#436797"
                            }
                        }
                        
                    }}
                >
                    Se connecter
                </Button>
            </div>
        );
    }
}

export default SubmitBtn;