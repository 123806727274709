import React, { Component } from "react";
import { Redirect, Route, Switch, BrowserRouter } from "react-router-dom";
import Dashboard from "./components/dashboard/Dashboard";
import { Interceptor } from "./components/Interceptor";
import Login from "./components/login/Login";
import PrivateRoute from "./components/PrivateRoute";
import "./style/style.css";

import { connect } from "react-redux";
import { doLogout } from "./redux/actions/userActions";

import { routes } from "./utils/router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Worker } from "@react-pdf-viewer/core";

class App extends Component {
  NotFound = () => (
    <div>
      <h1>404 - Not Found!</h1>
    </div>
  );

  render() {
    return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
        <BrowserRouter>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnHover
          />
          <Interceptor logoutUser={this.props.logout} />
          <Switch>
            <Route
              exact
              path={routes.HOME}
              render={(_) =>
                this.props.user.isLoggedIn ? (
                  <Redirect to={routes.DASHBOARD} />
                ) : (
                  <Redirect to={routes.LOGIN} />
                )
              }
            />

            <Route path={routes.LOGIN} component={Login} exact />
            <PrivateRoute
              isAdmin={this.props.user.role === 1}
              isLoggedIn={this.props.user.isLoggedIn}
              component={Dashboard}
              path={routes.DASHBOARD}
            />
            <Route component={this.NotFound}></Route>
          </Switch>
        </BrowserRouter>
      </Worker>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(doLogout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
