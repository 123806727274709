export const userActions = {
  SET_INFO: "CIMA/USER/SET_INFO",
  LOGOUT: "CIMA/USER/LOGOUT",
};

export function setUserInfo(payload) {
  return {
    type: userActions.SET_INFO,
    payload,
  };
}

export function doLogout() {
  return {
    type: userActions.LOGOUT,
  };
}
