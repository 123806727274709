import { actionTypes } from '../actions/reservationActions';

const initState = {
    reservations: {
        list: null,
        page: 1,
        total: 0
    },
    newReservations: {
        list: null,
        page: 1,
        total: 0
    },
    reservationStatus: "1"
}

const reservationsReducer=(state = initState,action) =>{
    let result = Object.assign({}, state);
    switch(action.type){
        case actionTypes.SET_NEW_RESERVATION :
            result.newReservations.list = action.payload.docs;
            result.newReservations.page = action.payload.page;
            result.newReservations.total = action.payload.totalDocs;
            return result;
        case actionTypes.SET_RESERVATIONS :
            result.reservations.list = action.payload.docs;
            result.reservations.page = action.payload.page;
            result.reservations.total = action.payload.totalDocs;
            return result;
        case actionTypes.SET_RESERVATIONS_STATUS: 
            result.reservationStatus = action.payload
            return result;
        default:
            return result;
    }
}

export default reservationsReducer;
