import React from "react";
import { Tag } from "baseui/tag";

export const wrappedDiv = (text, style) => {
  return (
    <div
      style={{
        maxWidth: "100%",
        overflow: "hidden",
        wordWrap: "break-word",
        ...style,
      }}
    >
      {text}
    </div>
  );
};

export const MapDeviceMeta = (meta) => {
  const toObj = JSON.parse(meta);
  const labels = { browser: "Navigateur", mobile: "Appareil", os: "OS" };
  return Object.keys(toObj).length ? (
    Object.keys(toObj).map((val, key) => (
      <Tag
        key={key}
        closeable={false}
        variant="solid"
        kind={
          val === "browser" ? "green" : val === "mobile" ? "orange" : "accent"
        }
      >
        {wrappedDiv(
          `${labels[val]}: ${
            val === "mobile" ? (toObj[val] ? "Mobile" : "Desktop") : toObj[val]
          }`,
          {
            fontSize: "11px",
          }
        )}
      </Tag>
    ))
  ) : (
    <></>
  );
};
