import { axiosApiInstance, urls } from "./apiUtils";
import { getAccessToken } from "./auth";

// headers: {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + getAccessToken(),
//   }

export const getReservations = (
  state,
  date = null,
  keyword = null,
  page = null,
  limit = null,
  callback
) => {
  axiosApiInstance({
    url: urls.GET,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getAccessToken(),
    },
    params: {
      state: state,
      date: date,
      keyword: keyword,
      page: page,
      limit: limit,
    },
  })
    .then((res) => {
      const status_code = res.status;
      switch (status_code) {
        case 200:
          console.log(res.data.msg);
          callback(res.data.res, 200);
          break;
        default:
          callback(null, status_code);
          break;
      }
    })
    .catch((error) => {
      if (error === "Error: Network Error") {
        // network error
        callback(null, 503);
      } else {
        const error_code = error.response ? error.response.status : 400;
        switch (error_code) {
          case 500:
            callback(null, 500);
            break;
          case 401:
            break;
          default:
            callback(null, error_code);
            break;
        }
      }
    });
};

export const getSingleReservation = (reservation_id, callback) => {
  axiosApiInstance({
    url: urls.GET_ONE.replace(":id", reservation_id),
    method: "GET",
    headers: {
      Authorization: "Bearer " + getAccessToken(),
    },
  })
    .then((res) => {
      const status_code = res.status;
      switch (status_code) {
        case 200:
          // console.log(res.data)
          callback(res.data.reservation, 200);
          break;
        default:
          callback(null, status_code);
          break;
      }
    })
    .catch((error) => {
      if (error === "Error: Network Error") {
        // network error
        callback(null, 503);
      } else {
        const error_code = error.response ? error.response.status : 400;
        switch (error_code) {
          case 500:
            callback(null, 500);
            break;
          case 401:
            break;
          default:
            callback(null, error_code);
            break;
        }
      }
    });
};

export const getReservationsCount = (callback) => {
  axiosApiInstance({
    url: urls.GET_STATES_COUNT,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getAccessToken(),
    },
  })
    .then((res) => {
      const status_code = res.status;
      switch (status_code) {
        case 200:
          callback(res.data.counts, 200);
          break;
        default:
          callback(null, status_code);
          break;
      }
    })
    .catch((error) => {
      if (error === "Error: Network Error") {
        // network error
        callback(null, 503);
      } else {
        const error_code = error.response ? error.response.status : 400;
        switch (error_code) {
          case 500:
            callback(null, 500);
            break;
          case 401:
            break;
          default:
            callback(null, error_code);
            break;
        }
      }
    });
};

export const patchReservation = (reservation_id, action, callback) => {
  console.log("patchReservation", reservation_id, action);

  axiosApiInstance({
    url: urls.PATCH + reservation_id,
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getAccessToken(),
    },
    data: {
      state: action,
    },
  })
    .then((res) => {
      const status_code = res.status;
      switch (status_code) {
        case 200:
          console.log(res.data.msg);
          callback(res.data.reservation, 200);
          break;
        default:
          callback(null, status_code);
          break;
      }
    })
    .catch((error) => {
      if (error === "Error: Network Error") {
        // network error
        callback(null, 503);
      } else {
        const error_code = error.response ? error.response.status : 400;
        switch (error_code) {
          case 500:
            callback(null, 500);
            break;
          case 401:
            break;
          default:
            callback(null, error_code);
            break;
        }
      }
    });
};

export const validateReservation = (reservation_id, data, callback) => {
  axiosApiInstance({
    url: urls.VALIDATE.replace(":id", reservation_id),
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getAccessToken(),
    },
    data: data,
  })
    .then((res) => {
      const status_code = res.status;
      switch (status_code) {
        case 200:
          console.log(res.data.msg);
          callback(res.data.reservation, 200);
          break;
        default:
          callback(null, status_code);
          break;
      }
    })
    .catch((error) => {
      if (error === "Error: Network Error") {
        // network error
        callback(null, 503);
      } else {
        const error_code = error.response ? error.response.status : 400;
        switch (error_code) {
          case 500:
            callback(null, 500);
            break;
          case 401:
            break;
          default:
            callback(null, error_code);
            break;
        }
      }
    });
};

export const downloadfile = (src, callback) => {
  let filename = src.split("/");
  filename = filename[filename.length - 1];

  axiosApiInstance({
    url: src,
    method: "GET",
    responseType: "blob",
  })
    .then((res) => {
      const status_code = res.status;
      switch (status_code) {
        case 200:
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("crossorigin", "anonymous");
          link.setAttribute("download", filename);

          // 3. Append to html page
          document.body.appendChild(link);
          // 4. Force download
          link.click();
          // 5. Clean up and remove the link
          link.parentNode.removeChild(link);

          callback(status_code);
          break;
        default:
          callback(status_code);
          break;
      }
    })
    .catch((error) => {
      callback(error);
      console.log(error);
    });
};

export const exportReservations = (startDate, endDate, callback) => {
  axiosApiInstance({
    url: urls.EXPORT,
    method: "GET",
    params: {
      startDate,
      endDate,
      token: getAccessToken(),
    },
    responseType: "blob",
  })
    .then((res) => {
      const status_code = res.status;
      switch (status_code) {
        case 200:
          let file = res.request.responseURL;

          //window.open(file);
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("crossorigin", "anonymous");
          link.setAttribute(
            "download",
            `reservations_${startDate
              .toISOString()
              .slice(0, 10)}_${endDate.toISOString().slice(0, 10)}.xlsx`
          );

          // 3. Append to html page
          document.body.appendChild(link);
          // 4. Force download
          link.click();
          // 5. Clean up and remove the link
          link.parentNode.removeChild(link);

          callback(status_code);
          break;
        default:
          callback(status_code);
          break;
      }
    })
    .catch((error) => {
      console.log("ERROR ", error);
      if (error === "Error: Network Error") {
        // network error
        callback(500);
      } else {
        const error_code = error.response ? error.response.status : 500;
        switch (error_code) {
          case 500:
            callback(500);
            break;
          default:
            callback(error_code);
            break;
        }
      }
    });
};
